import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
  Container,
  Title,
  Column,
  Columns,
  Button,
  Level,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardImage,
  Image,
  CardHeaderTitle,
  Content,
} from "bloomer"

import Layout from "../components/Layout"
import Seo from "../components/seo"

function portfolio() {
  return (
    <Layout>
      <Seo title="portfolio" />
      <Container style={{ alignSelf: "start" }}>
        <Columns className="">
          <Card className="is-one-third mx-2 ">
            <CardHeader>
              <CardHeaderTitle>Collaborative Whiteboard</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/react-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="react-logo"
                />
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/node-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="node-logo"
                />
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/express.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="express-logo"
                />
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/socket-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="express-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/whiteboard.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                Collaborative whiteboard app in which multiple users can draw
                together <br /> with results updating on all clients in real
                time.
                <p>
                  <strong>
                    Built with: <em>React, Node JS, Express, Socket.io</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://collaborative-whiteboard.herokuapp.com/"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://github.com/paweljm/whiteboard-collab-app"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
          <Card className="is-one-third mx-2 ">
            <CardHeader>
              <CardHeaderTitle>Drum Machine</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/react-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="react-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/drum.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                Drum pad with each sound being streamed from sources on the
                internet <br />
                and supporting keyboard input
                <p>
                  <strong>
                    Built with: <em>React, Bootstrap</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://codepen.io/paweljm/full/QWdPVrp"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://codepen.io/paweljm/pen/QWdPVrp"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
          <Card className="is-one-third mx-2 ">
            <CardHeader>
              <CardHeaderTitle>Quote Generator</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/react-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="react-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/quote.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                Generates random quotes with the option to tweet and randomise
                for another <br /> quotes fetched from an API
                <p>
                  <strong>
                    Built with: <em>React, Bootstrap</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://codepen.io/paweljm/full/RwKzZGY"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://codepen.io/paweljm/pen/RwKzZGY"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
        </Columns>
        <Columns>
          <Card className="is-one-third mx-2 mt-2 ">
            <CardHeader>
              <CardHeaderTitle>To-do List</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/js-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="js-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/todo.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                Allows user to create and edit projects as well as todo's within{" "}
                <br /> utilises local storage for persistant data
                <p>
                  <strong>
                    Built with: <em>JavaScript</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://pawelm-todo.netlify.app/"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://github.com/paweljm/Todo-list-app"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
          <Card className="is-one-third mx-2 mt-2 ">
            <CardHeader>
              <CardHeaderTitle>Markdown Previewer</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/react-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="react-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/markdown.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                An application to parse markdown from an editor to a live
                preview
                <br /> has example I wrote showing common markdown
                <p>
                  <strong>
                    Built with: <em>React, Bootstrap</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://codepen.io/paweljm/full/abpxWBe"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://codepen.io/paweljm/pen/abpxWBe"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
          <Card className="is-one-third mx-2 mt-2 ">
            <CardHeader>
              <CardHeaderTitle>Tic Tac Toe Game</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/js-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="js-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/tictactoe.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                Tic tac toe game for 2 players in vanilla JS has option to play
                vs computer
                <br /> which selects random cells and disables player 2
                <p>
                  <strong>
                    Built with: <em>JavaScript</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://pawelm-tictactoe.netlify.app/"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://github.com/paweljm/TicTacToeGame"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
        </Columns>
        <Columns>
          <Card className="is-one-third mx-2 mt-2 ">
            <CardHeader>
              <CardHeaderTitle>Calculator (React)</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/react-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="react-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/calculator.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                Calculator project built with React, utilising the Bulma css
                framework
                <br /> cleaner look than my previous pure JS version
                <p>
                  <strong>
                    Built with: <em>React, Bulma</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://codepen.io/paweljm/full/mdRZMxo"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://codepen.io/paweljm/pen/mdRZMxo"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
          <Card className="is-one-third mx-2 mt-2 ">
            <CardHeader>
              <CardHeaderTitle>Calculator (Vanilla)</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/js-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="js-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/calcJS.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                An older calculator I made when learning JS which I later
                re-made using React
                <br /> vanilla JS was used, still sufficiently functional
                <p>
                  <strong>
                    Built with: <em>JavaScript</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://pawelm-calculator.netlify.app/"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://github.com/paweljm/JS-Calculator"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
          <Card className="is-one-third mx-2 mt-2 ">
            <CardHeader>
              <CardHeaderTitle>Etch a Sketch</CardHeaderTitle>
              <div className=" p-1 is-small level">
                <StaticImage
                  className="is-pulled-right mx-1"
                  src="../images/js-logo.png"
                  height={30}
                  backgroundColor="transparent"
                  placeholder="none"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="js-logo"
                />
              </div>
            </CardHeader>
            <CardImage className="justify-content-center">
              <StaticImage className="mx-1" src="../images/etch.png" />
            </CardImage>
            <CardContent>
              <Content className="is-full has-text-centered">
                This is a simple app when I was learning DOM manipulation and
                events
                <br /> change square colors randomly on hover
                <p>
                  <strong>
                    Built with: <em>JavaScript</em>
                  </strong>
                </p>
              </Content>
            </CardContent>
            <CardFooter>
              <a
                href="https://pawelm-etchsketch.netlify.app"
                target="blank"
                className="card-footer-item button"
              >
                See Live
              </a>
              <a
                href="https://github.com/paweljm/Etch-a-sketch"
                target="blank"
                className="card-footer-item button"
              >
                See Code
              </a>
            </CardFooter>
          </Card>
        </Columns>
      </Container>
    </Layout>
  )
}

export default portfolio
